<template>
  <router-link v-if="supplier" :to="{name: 'Supplier', params: {id: supplier.id}}">
    {{ supplier.name }}
  </router-link>
  <span v-else>{{ $t('common.misc.NA') }}</span>
</template>

<script>
export default {
  name: "SupplierLink",

  props: {
    supplier: {type: Object, default: () => null}
  }
}
</script>

<style scoped>

</style>
